package root_pages.aurinko_pages.app.dashboard

import com.github.uosis.laminar.webcomponents.material.Textfield
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common._
import portal_router.{AccountPage, DashboardPage, PortalRouter}
import service.apis.portal_api.PortalApi
import common.ui.notifications.LocalMessagesView

import java.time.{Instant, LocalDate, LocalDateTime}
import java.time.temporal.ChronoUnit
import org.scalajs.dom.html

import scala.scalajs.js
import org.scalajs.dom
import facade.{ChartJS, ChartJSAuto}
import wvlet.log.Logger

import java.time.format.DateTimeFormatter
import scala.scalajs.js.JSConverters.JSRichIterableOnce

class AppUsageComponent($route: Signal[DashboardPage],
                        usage: Signal[List[Usage]],
                        portalApi: PortalApi,
                        portalRouter: PortalRouter
                       ) {

  private val log = Logger.of[AppUsageComponent]

  val usageData: Var[List[Usage]] = Var(Nil)
  val topUsageData: Var[List[Usage]] = Var(Nil)
  val NewChart = new ChartUsageData(
    usageData.signal,
    (s, e) => Var.set(startDateTop -> s, endDateTop -> e),
    showLegend = false,
    useMultipleColors = false
  )

  val showInfoMessage: Var[Boolean] = Var(false)

  def formatBytes(bytes: Long): String = {
    if (bytes >= byteToTb) {
      f"${bytes / byteToTb}%.1f TB"
    } else if (bytes >= byteToGb) {
      f"${bytes / byteToGb}%.1f GB"
    } else if (bytes >= byteToMb) {
      f"${bytes / byteToMb}%.1f MB"
    } else if (bytes >= byteToKb) {
      f"${bytes / byteToKb}%.1f KB"
    } else if (bytes > 0) {
      s"$bytes B"
    } else {
      "0 B"
    }
  }

  val startDateTop = Var(LocalDateTime.ofInstant(Instant.now.minus(29, ChronoUnit.DAYS), systemDefaultTZ).toLocalDate)
  val endDateTop = Var(LocalDateTime.ofInstant(Instant.now, systemDefaultTZ).toLocalDate)

  val startInput = Textfield(
    _ => padding := "0px",
    _ => maxHeight := "30px",
    _ => maxWidth := "10rem",
    _.outlined := true,
    _.placeholder := "2024-06-05",
    _.value <-- startDateTop.signal.map { value =>
      println(s"Displaying value: $value")
      value.toString
    },
    _ => onInput.mapToValue.filter(_.matches("\\d{4}-\\d{2}-\\d{2}")).map(LocalDate.parse) --> { value =>
      println(s"Input value: $value")
      startDateTop.set(value)
    },
    _.required := true,
  )

  val endInput = Textfield(
    _ => padding := "0px",
    _.`type` := "date-time",
    _ => maxHeight := "30px",
    _ => maxWidth := "10rem",
    _.outlined := true,
    _.placeholder := "2024-06-07",
    _.value <-- endDateTop.signal.map { date =>
      println(s"Signal endDateTop value: $date")
      date.toString
    },
    _ => onInput.mapToValue.filter(_.matches("\\d{4}-\\d{2}-\\d{2}")).map(LocalDate.parse) --> { value =>
      println(s"Input endDateTop: $value")
      endDateTop.set(value)
    },
    _.required := true,
  )

  val eventsBinders = List(
    $route
      .flatMap(r => portalApi.appUsage(r.appKey
      ))
      --> usageData.writer,
  )

  val node: ReactiveHtmlElement[html.Div] = div(
    eventsBinders,
    cls := "slds-m-bottom--large slds-grid",
    div(
      maxWidth := "60rem",
      width := "100%",
      div(
        height := "6rem",
        p(cls := "title--level-2 ", "App usage"),
        div(
          cls := "slds-m-top--medium",
          child.text <-- usageData.signal.map { records =>
            records.map(_.bytes).sum
          }.map {
            case bytes if bytes >= byteToTb => f"Total for the last 30days: ${bytes / byteToTb}%.1f TB"
            case bytes if bytes >= byteToGb => f"Total for the last 30days: ${bytes / byteToGb}%.1f GB"
            case bytes if bytes >= byteToMb => f"Total for the last 30days: ${bytes / byteToMb}%.1f MB"
            case bytes if bytes >= byteToKb => f"Total for the last 30days: ${bytes / byteToKb}%.1f KB"
            case bytes if bytes > 0 => f"Total for the last 30days: $bytes B"
            case _ => s"Total for the last 30days: 0 B"
          },
        ),
        small(
          cls := "grey  slds-m-bottom--xxx-large",
          fontStyle := "italic",
          "*Click on a bar to view top usage for that specific day.")
      ),

      div(
        NewChart.node
      )
    ),
    child <-- startDateTop.signal
      .combineWith(endDateTop.signal).map {
        case (start, end) => {
          val daysB = ChronoUnit.DAYS.between(start, end).toInt

          div(
            if (daysB <= 31) {
              $route.flatMap(r => portalApi.profileUsage(r.appKey, start.toString, end.toString
              )) --> {
                showInfoMessage.set(false)
                topUsageData.writer
              }
            } else {
              Signal.fromValue(true) --> showInfoMessage.writer
            },
            cls := "slds-m-left--xxx-large",
            flex := "0 0 25rem",
            div(
              div(
                p(cls := "title--level-2 slds-m-right--medium",
                  s"Top usage:"),
                child.maybe <-- showInfoMessage.signal.map { show =>
                  if (show) Some(
                    div(
                      cls := "topUsage-block",
                      LocalMessagesView(
                        Signal.fromValue(Some("The period cannot exceed 31 days.")),
                        Signal.fromValue(Some("message")),
                      )))
                  else None
                },
              ),
              div(
                cls := "slds-m-top--small usage-text slds-grid slds-grid_vertical-align-center",
                span(cls := "slds-m-right--medium", "from"),
                startInput,
                span(cls := "slds-m-right--medium slds-m-left--medium", "to"),
                endInput
              )
            ),

            div(
              cls := "data-table",
              paddingBottom := "0",
              div(
                cls := "table-header",
                span(cls := "slds-col slds-size--9-of-12 slds-m-right--small", "Name"),
                span(cls := "slds-col slds-size--3-of-12 gray", "Units"),
              ),

              child.maybe <-- topUsageData.signal.map {
                case sortedList if sortedList.nonEmpty => Some(div(
                  child <-- topUsageData.signal
                    .map {
                      case topUsageList if topUsageList.nonEmpty => div(
                        topUsageList.map{
                          case topProf if topProf.bytes > 0 =>
                         Some(div(
                            cls := "table-row panel-like border-bottom--light slds-p-top--small slds-wrap",
                            div(
                              cls := "slds-col slds-size--12-of-12 slds-grid",
                              div(cls := "slds-col slds-size--9-of-12 slds-m-right--small au-truncate",
                                whiteSpace := "nowrap",
                                topProf.key
                              ),

                              span(cls := "slds-col slds-size--3-of-12", div(
                                formatBytes(topProf.bytes)
//                                if (topProf.bytes >= byteToTb) {
//                                  f"${topProf.bytes / byteToTb}%.1f TB"
//                                } else if (topProf.bytes >= byteToGb) {
//                                  f"${topProf.bytes / byteToGb}%.1f GB"
//                                } else if (topProf.bytes >= byteToMb) {
//                                  f"${topProf.bytes / byteToMb}%.1f MB"
//                                } else if (topProf.bytes > 0) {
//                                  f"${topProf.bytes / byteToKb}%.1f KB"
//                                } else {
//                                  "0 KB"
//                                }
                              )
                              )

                            ),

                            topProf.accounts.flatMap {
                              case acc if acc.exists(ac => !ac.removed && ac.bytes > 0) =>
                                Some(div(
                                  cls := "slds-col slds-size--12-of-12 slds-m-top--xxx-small slds-grid",
                                  span(
                                    cls := "slds-shrink-none slds-m-right--xx-small",
                                    small(cls := "gray", "Account id: ")
                                  ),
                                  div(
                                    cls := "slds-grid slds-wrap slds-grid_vertical-align-end",
                                    topProf.accounts.getOrElse(Nil).collect {
                                      case ac if !ac.removed && ac.bytes > 0 =>
                                        a(
                                          padding := "0px",
                                          small(cls := "slds-m-right--small", s"${ac.id} (${formatBytes(ac.bytes)})"),
                                          href <-- $route.map(r => portalRouter.router.absoluteUrlForPage(AccountPage(r.appKey, ac.id.toInt)))
                                        )
                                    }
                                  )
                                ))
                              case _ => None
                            },

                            topProf.accounts.flatMap {
                              case acc if acc.exists(ac => ac.removed && ac.bytes > 0) =>
                                Some(div(
                                  cls := "slds-col slds-size--12-of-12 slds-m-top--xxx-small slds-grid",
                                  span(
                                    cls := "slds-shrink-none gray slds-p-right--small",
                                    paddingRight := "10px",
                                    small(cls := "gray", "Removed accounts with usage: ")
                                  ),
                                  div(
                                    cls := "slds-grid slds-wrap",
                                    topProf.accounts.getOrElse(Nil).collect {
                                      case ac if ac.removed && ac.bytes > 0 =>
                                        span(
                                          padding := "0px",
                                          small(cls := "gray slds-m-right--small", s"${ac.id} (${formatBytes(ac.bytes)})")
                                        )
                                    }
                                  )
                                ))
                              case _ => None
                            }
                          ))
                          case _ => None
                        }
                      )
                      case _ => div(
                        cls := "usage-text slds-m-top--medium ",
                        textAlign := "center",
                        "No usage in these days"
                      )
                    }
                ))
                case _ => Some(div(
                  cls := "usage-text slds-m-top--medium",
                  textAlign := "center",
                  "No usage in these days"
                ))
              },
            )
          )
        }
      },
  )
}

class AccountUsageComponent($route: Signal[AccountPage],
                            usage: Signal[List[Usage]],
                            portalApi: PortalApi,
                           ) {

  private val log = Logger.of[AccountUsageComponent]

  val usageAccountData: Var[List[Usage]] = Var(Nil)

  val NewChart = new ChartUsageData(usageAccountData.signal, showLegend= false, heightChart = "12rem")

  val node: ReactiveHtmlElement[html.Div] = div(
    cls := "slds-m-top--xx-large slds-grid slds-grid_vertical",
    maxWidth := "60rem",
    width := "100%",

    $route.flatMap(r => portalApi.accountUsage(r.appKey, r.accId)) --> usageAccountData.writer,
    p(
      cls := "title--level-3 ",
      "Account usage"
    ),
    child <-- usageAccountData.signal.map { app =>
      val totalBytes: Signal[Long] = usageAccountData.signal.map { records =>
        records.foldLeft(0L) { (acc, record) =>
          acc + record.bytes
        }
      }
      div(
        div(
          cls := "slds-m-top--medium slds-m-bottom--large",
          span("Total for the last 30days:"),
          child.text <-- totalBytes.map {
            case bytes if bytes >= byteToTb => f" ${bytes / byteToTb}%.1f TB"
            case bytes if bytes >= byteToGb => f" ${bytes / byteToGb}%.1f GB"
            case bytes if bytes >= byteToMb => f" ${bytes / byteToMb}%.1f MB"
            case bytes if bytes >= byteToKb => f" ${bytes / byteToKb}%.1f KB"
            case bytes if bytes > 0 => f" $bytes B"
            case _ => " 0 B"
          }
        ),
        NewChart.node
      )
    }
  )
}

class ChartUsageData(records: Signal[List[Usage]],
                     onChangeDate: (LocalDate, LocalDate) => Unit = (_, _) => (),
                     showLegend: Boolean = true,
                     useMultipleColors: Boolean = true,
                     heightChart: String = "20rem"
                    ) {
  var chartInstance: Option[ChartJS.Chart] = None

  val node: ReactiveHtmlElement[html.Div] = div(
    child <-- records.map(record => {
      canvas(
        cls := "myChart",
        maxWidth := "60rem",
        width := "100%",
        maxHeight := heightChart,
        onMountCallback(_ => initChart(record, showLegend)),
        onUnmountCallback(_ => destroyChart())
      )
    }
    )
  )

  def destroyChart(): Unit = {
    chartInstance.foreach { chart =>
      chart.destroy()
    }
    chartInstance = None
  }

  def getColorForBytes(bytes: Long): String = {
    val gigabytes = bytes / byteToGb
    if (useMultipleColors) {
      gigabytes match {
        case g if g < 1 => "rgba(0, 128, 0, 0.4)" // green
        case g if g >= 1 && g < 5 => "rgba(255, 206, 86, 0.4)" // yellow
        case _ => "rgba(255, 0, 0, 0.4)" // red
      }
    } else {
      "rgba(247, 147, 30, 0.4)"
    }
  }

  def getBorderColorForBytes(bytes: Long): String = {
    val gigabytes = bytes / byteToGb
    if (useMultipleColors) {
      gigabytes match {
        case g if g < 1 => "rgba(0, 128, 0, 1)" // green
        case g if g >= 1 && g < 5 => "rgba(255, 206, 86, 1)" // yellow
        case _ => "rgba(255, 0, 0, 1)" // red
      }
    } else {
      "rgba(247, 147, 30, 1)"
    }
  }

  def prepareChartData(records: List[Usage],
                      ) = {

    val totalBytes = records.map(_.bytes).sum
    val scaledData = totalBytes match {
      case bytes if bytes >= byteToTb => records.map(_.bytes / byteToTb)
      case bytes if bytes >= byteToGb => records.map(_.bytes / byteToGb)
      case bytes if bytes >= byteToMb => records.map(_.bytes / byteToMb)
      case bytes if bytes >= byteToKb => records.map(_.bytes / byteToKb)
      case _ => records.map(_.bytes.toDouble)
    }

    val dateFormatter = DateTimeFormatter.ofPattern("MMM d")
    val labels = records.map(record => record.reportStart.format(dateFormatter)).toJSArray

    val backgroundColors = records.map(records => getColorForBytes(records.bytes)).toJSArray
    val borderColors = records.map(records => getBorderColorForBytes(records.bytes)).toJSArray

    js.Dynamic.literal(
      labels = labels,
      datasets = js.Array(
        js.Dynamic.literal(
          label = "Usage",
          data = scaledData.toJSArray,
          backgroundColor = backgroundColors,
          borderColor = borderColors,
          borderWidth = 1
        )
      )
    )
  }

  def initChart(records: List[Usage],
                showLegend: Boolean = true,
               ): Unit = {
    ChartJSAuto

    val totalBytes = records.map(_.bytes).sum
    val yAxisTitle = totalBytes match {
      case bytes if bytes >= byteToTb => "TB"
      case bytes if bytes >= byteToGb => "GB"
      case bytes if bytes >= byteToMb => "MB"
      case bytes if bytes >= byteToKb => "KB"
      case bytes if bytes > 0 => "B"
      case _ => "B"
    }

    chartInstance.foreach(_.destroy())
    val canvas = dom.document.getElementsByClassName("myChart")(0).asInstanceOf[dom.html.Canvas]
    val ctx = canvas.getContext("2d")
    val chartData = prepareChartData(records)

    chartInstance = Some(new ChartJS.Chart(ctx, js.Dynamic.literal(
      `type` = "bar",
      data = chartData,
      options = js.Dynamic.literal(
        scales = js.Dynamic.literal(
          y = js.Dynamic.literal(
            title = js.Dynamic.literal(
              display = true,
              text = yAxisTitle
            ),
            beginAtZero = true,
            min = 0,
            ticks = js.Dynamic.literal(
              callback = (value: js.Dynamic, index: Int, values: js.Array[js.Dynamic]) => {
                value.toString
              }
            ),
            grid = js.Dynamic.literal(
              drawOnChartArea = false
            )
          )
        ),
        plugins = js.Dynamic.literal(
          tooltip = js.Dynamic.literal(
            enabled = true,
            mode = "index",
            intersect = false,
            callbacks = js.Dynamic.literal(
              label = (context: js.Dynamic) => {
                val value = context.raw.asInstanceOf[Double]
                val unit = yAxisTitle match {
                  case "TB" => "TB"
                  case "GB" => "GB"
                  case "MB" => "MB"
                  case "KB" => "KB"
                  case "B" => "B"
                  case _ => "B"
                }
                f"${value}%,.2f $unit"
              }
            )
          ),
          legend = js.Dynamic.literal(
            display = showLegend,
            position = "bottom",
            labels = js.Dynamic.literal(
              generateLabels = (chart: js.Dynamic) => {
                js.Array(
                  js.Dynamic.literal(
                    text = "Usage < 1GB/30days",
                    fillStyle = "rgba(0, 128, 0, 0.4)"
                  ),
                  js.Dynamic.literal(
                    text = "Usage < 5GB/30days",
                    fillStyle = "rgba(255, 206, 86, 0.4)"
                  ),
                  js.Dynamic.literal(
                    text = "Unlimited usage",
                    fillStyle = "rgba(255, 0, 0, 0.4)"
                  )
                )
              }
            )
          ),
        ),
        js.Dynamic.literal(
          responsive = false, // делает график адаптивным
          maintainAspectRatio = false, // отключает сохранение пропорций
        ),

        onClick = (event: js.Dynamic, elements: js.Array[js.Dynamic]) => {
          if (elements.nonEmpty) {
            val selectedRecord = records(elements(0).index.asInstanceOf[Int])
            onChangeDate(selectedRecord.reportStart, selectedRecord.reportEnd)
          }
        }
      )
    )))
  }
}










