package root_pages.aurinko_pages.app.settings

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.Icon
import com.raquo.laminar.api.L
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.StoreType.StoreType
import common._
import common.airstream_ops._
import common.forms.FormsLocalExceptionHandling
import common.ui.au_storage_view.StorageComponent
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.icons.MaterialIcons
import common.value_opps._
import common.ui.{AuFormState, AuFormStateExp}
import io.circe.{Decoder, Encoder}
import io.circe.generic.auto.exportEncoder
import io.circe.syntax.EncoderOps
import org.scalajs.dom.html
import portal_router.{AppSettingsPage, PortalRouter}
import service.apis.portal_api.PortalApi
import service.clipboard_service.ClipboardService
import service.portal_state.{PortalState, TeamMemberAccess}
import wvlet.log.Logger

import scala.scalajs.js.timers.setTimeout
import scala.util.Try

class SettingsComponent($route: Signal[AppSettingsPage],
                        portalApi: PortalApi,
                        clipboardService: ClipboardService,
                        apiOrigin: String,
                        portalRouter: PortalRouter,
                        portalState: PortalState
                       ) {

  private val log = Logger.of[SettingsComponent]
  private lazy val teamMemberAccess = new TeamMemberAccess(portalState.$team)

  lazy val $appRegs: Signal[Option[List[PortalAppRegistration]]] = portalState.$appRegs
    .$orElse(
      portalState
        .$teamApp
        .map(_.appKey)
        .flatMap(portalApi.requestAppRegs)
        .tap(l => portalState.updateAppRegs(l))
        .map(_.some)
        .startWith(None))

  private val updateAppRegsBus: EventBus[Unit] = new EventBus[Unit]

  private def callBacks: DynamicFormComponent[PortalApplication] = DynamicFormComponent(
    CallbacksFormConfig.instance,
    portalApi,
    portalState.FormCache,
    $initialExpand = $route.map(_.expand.contains(SettingsSection.callbacks)),
    portalState.$app.map(_.allowedReturnUrls).getOrElse(Nil),
    (urls: List[String]) => $route
      .map(_.appKey)
      .flatMap(appKey => portalApi.updateAllowedUrls(appKey, urls).mapTo(appKey))
      .flatMap(portalApi.application),
    Observer[PortalApplication](app => portalState.updateApp(app.some)),
    portalState.Session.$sessionExpiredEvents
  )

  private def trustedDomains: DynamicFormComponent[PortalApplication] = DynamicFormComponent(
    DomainsFormConfig.instance,
    portalApi,
    portalState.FormCache,
    $initialExpand = $route.map(_.expand.contains(SettingsSection.domains)),
    portalState.$app.map(_.allowedOrigins).getOrElse(Nil),
    (urls: List[String]) => $route.map(_.appKey)
      .flatMap(appKey => portalApi.updateAllowedOrigins(appKey, urls).mapTo(appKey))
      .flatMap(portalApi.application),
    Observer[PortalApplication](app => portalState.updateApp(app.some)),
    portalState.Session.$sessionExpiredEvents
  )

//  private val storeComponent = $route.map(r =>
//    StorageComponent(
//      appKey = r.appKey,
//      instId = r.appKey.appId.toString,
//      storeType = StoreType.application,
//      portalApi = portalApi,
//      $sessionExpiredEvents = portalState.Session.$sessionExpiredEvents
//  )).map(component => {
//
//    ExpansionPanelComponent(
//      header = p(
//        "Storage",
//        cls := "title--level-2",
//        cls <-- component.nonEmpty.map(if (_) "" else "light")
//      ),
//      body = component.node.some,
//      expanded = $route
//        .map(_.expand)
//        .map {
//          case Some(SettingsSection.storage) => true
//          case _ => false
//        }
//    )
//  })

  private def googleRegistration: GoogleOAuth = GoogleOAuth(
    $appRegs.subflatMap(_.find(_.serviceType == ServiceType.google)),
    "Google OAuth",
    updateAppRegsBus,
    ServiceType.google,
    portalApi,
    portalState,
    $initialExpand = $route
      .map(_.expand)
      .map {
        case Some(SettingsSection.AppReg(st)) => st == ServiceType.google
        case _ => false
      },
    apiOrigin = apiOrigin,
    clipboardService = clipboardService
  )

  private def slackRegistration: SlackOAuth = SlackOAuth(
    $appRegs.subflatMap(x => x.find(_.serviceType == ServiceType.slackBot)),
    "Slack Bot OAuth",
    updateAppRegsBus,
    ServiceType.slackBot,
    portalApi,
    portalState,
    $initialExpand = $route
      .map(_.expand)
      .map {
        case Some(SettingsSection.AppReg(st)) => st == ServiceType.slackBot
        case _ => false
      },
    apiOrigin = apiOrigin,
    clipboardService = clipboardService
  )

  private def msBotRegistration: MSBot = MSBot(
    $appRegs.subflatMap(x => x.find(_.serviceType == ServiceType.msTeamsBot)),
    updateAppRegsBus,
    portalApi,
    portalState,
    ServiceType.msTeamsBot,
    $initialExpand = $route
      .map(_.expand)
      .map {
        case Some(SettingsSection.AppReg(st)) => st == ServiceType.msTeamsBot
        case _ => false
      },
    apiOrigin = apiOrigin,
    clipboardService = clipboardService
  )

  private def sugarCrmRegistration: SugarCrmOAuth = SugarCrmOAuth(
    $appRegs.subflatMap(x => x.find(_.serviceType == ServiceType.sugarCRM)),
    "Sugar CRM OAuth",
    updateAppRegsBus,
    ServiceType.sugarCRM,
    portalApi,
    portalState,
    $initialExpand = $route
      .map(_.expand)
      .map {
        case Some(SettingsSection.AppReg(st)) => st == ServiceType.sugarCRM
        case _ => false
      },
  )

  private def fishbowlRegistration: FishbowlOAuth = FishbowlOAuth(
    $appRegs.subflatMap(x => x.find(_.serviceType == ServiceType.fishbowl)),
    "Fishbowl OAuth",
    updateAppRegsBus,
    ServiceType.fishbowl,
    portalApi,
    portalState,
    $initialExpand = $route
      .map(_.expand)
      .map {
        case Some(SettingsSection.AppReg(st)) => st == ServiceType.fishbowl
        case _ => false
      },
  )

  private def AcumaticaRegistration: AcumaticaOAuth = AcumaticaOAuth(
    $appRegs.subflatMap(x => x.find(_.serviceType == ServiceType.acumatica)),
    "Acumatica OAuth",
    updateAppRegsBus,
    ServiceType.acumatica,
    portalApi,
    portalState,
    $initialExpand = $route
      .map(_.expand)
      .map {
        case Some(SettingsSection.AppReg(st)) => st == ServiceType.acumatica
        case _ => false
      },
    apiOrigin = apiOrigin,
    clipboardService = clipboardService,
  )

  private def expandAndFormCachingBinders(appReg: AppRegistrationComponent): Div = appReg.node.amendThis(th => List(
    EventStream.fromValue(())
      .delay(450)
      .withCurrentValueOf(appReg.isExpanded.signal) --> Observer[Boolean](onNext = {
      case true => th.ref.scrollIntoView(true)
      case _ => ()
    }),

    appReg.editModel.signal.map(_.foreach { m =>
      portalState.FormCache.model.foreach {
        case cm: AppRegistrationModel if m.serviceType == cm.serviceType =>
          m.updateFromModel(cm)
          appReg.isExpanded.set(true)
          portalState.FormCache.resetCache()

          setTimeout(0) {
            appReg.formState.now.foreach(_.validate())
          }
        case _ => ()
      }
    }) --> Observer.empty,
  ))

  private def transformToAppRegistrationComponent(t: (common.ServiceType, common.AuthOption.AuthType)): Option[AppRegistrationComponent] = t match {
    case (serviceType, common.AuthOption.OAuth2BasicAppRegSettings) =>
      OAuthBasic(
        $appRegs.subflatMap(x => x.find(srv => srv.serviceType == serviceType && !srv.daemon)),
        s"${serviceType.label} OAuth",
        updateAppRegsBus,
        serviceType,
        portalApi,
        portalState,
        $initialExpand = $route
          .map(_.expand)
          .map {
            case Some(SettingsSection.AppReg(st)) => st == serviceType
            case _ => false
          },
        apiOrigin = apiOrigin,
        clipboardService = clipboardService,
        showSecretFields = serviceType.authOptions.contains(common.AuthOption.SecretFields),
        showNativeScopesField = serviceType.authOptions.contains(common.AuthOption.NativeScopes),
      ).some
    case (serviceType, common.AuthOption.DaemonBasicAppRegSettings) =>
      DaemonBasic(
        $appRegs.subflatMap(_.find(srv => srv.serviceType == serviceType && srv.daemon)),
        s"${serviceType.label} OAuth Daemon",
        updateAppRegsBus,
        serviceType,
        portalApi,
        portalState,
        apiOrigin = apiOrigin,
        clipboardService = clipboardService
      ).some
    case (serviceType, authOption) if authOption.hasSpecialAppReg => serviceType match {
      case ServiceType.google => googleRegistration.some
      case ServiceType.slackBot => slackRegistration.some
      case ServiceType.msTeamsBot => msBotRegistration.some
      case ServiceType.sugarCRM => sugarCrmRegistration.some
      case ServiceType.fishbowl => fishbowlRegistration.some
      case ServiceType.acumatica => AcumaticaRegistration.some
      case _ =>
        log.warn(s"not implemented settings for ${serviceType.value}")
        Option.empty[AppRegistrationComponent]
    }
    case _ =>
      Option.empty[AppRegistrationComponent]
  }

  val node: Div = div(
    cls := "content-padding",
    child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer).andThen {
      div(
        ApplicationDetails.LeftSection(portalApi, portalState, updateAppRegsBus, portalRouter),
        div(
          cls := "right-section",
          div(
            cls := "slds-m-top--large",
            callBacks.node.amendThis(th =>
              $route.map(_.expand.contains(SettingsSection.callbacks))
                .stream
                .delay(450) --> Observer[Boolean](onNext = {
                case true => th.ref.scrollIntoView(true)
                case _ => ()
              })),

            trustedDomains.node.amendThis(th =>
              $route.map(_.expand.contains(SettingsSection.domains))
                .stream
                .delay(450) --> Observer[Boolean](onNext = {
                case true => th.ref.scrollIntoView(true)
                case _ => ()
              })),

//            child <-- storeComponent.map(_.node.amendThis(th => List(
//              $route.map(_.expand.contains(SettingsSection.storage))
//                .stream
//                .delay(450) --> Observer[Boolean](onNext = {
//                case true => th.ref.scrollIntoView(true)
//                case _ => ()
//              }),
//              cls := "slds-m-top--xx-large"))),

            children <-- portalState.$app
              .combineWith($appRegs.map(_.exists(_.exists(app => app.daemon && app.serviceType == ServiceType.office365))))
              .combineWith(portalState.$app.map(_.capabilitiesSet))
              .map {
                case (app, hasOffice365DaemonAppReg, capabilities) => app.allowedServiceTypes.toSeq
                  .sortBy(_.weight)
                  .map {
                    case serviceType if serviceType == ServiceType.office365 && !hasOffice365DaemonAppReg =>
                      serviceType.authOptions
                        .filter(_ != common.AuthOption.DaemonBasicAppRegSettings)
                        .sorted // does not have office365 daemon registration
                        .map((serviceType, _))
                    case serviceType =>
                      serviceType.authOptions
                        .sorted
                        .map((serviceType, _))
                  }
                  .expand
                  .sortBy(_._1.weight)
                  .flatMap(transformToAppRegistrationComponent)
                  .groupBy(appReg => capabilities.intersect(appReg.serviceType.capabilities).toSeq.sortBy(_.weight).headOption)
                  .filter(_._2.nonEmpty)
                  .map(regs => regs.copy(_2 = regs._2.sortBy(_.serviceType.weight)))
                  .toSeq
                  .sortBy(_._1.map(_.weight).getOrElse[Int](Int.MaxValue))
                  .map {
                    reg =>
                      div(
                        common.ui.Attribute.Selector := reg._1.map(_.name).getOrElse[String](common.PortalAppCapability.unknown.name),
                        cls := "slds-m-top--xx-large",
                        reg._2.map(expandAndFormCachingBinders)
                      )
                  }
                case _ => Seq.empty
              }
          )),
      )
    },
    updateAppRegsBus.events
      .withCurrentValueOf($route.map(_.appKey))
      .flatMap(appKey => for {
        regs <- portalApi.requestAppRegs(appKey)
        app <- portalApi.application(appKey)
      } yield regs -> app)
      --> Observer[(List[PortalAppRegistration], PortalApplication)]({ case (regs, app) =>
      portalState.updateAppRegs(regs)
      portalState.updateApp(Some(app))
    }),
  )
}

trait SettingsSection {
  def name: String
}

object SettingsSection {
  private val log = Logger.of[SettingsSection]

  private case class Internal(name: String) extends SettingsSection

  case class AppReg(serviceType: common.ServiceType) extends SettingsSection {
    def name: String = s"AppReg:${serviceType.value}"
  }

  def apply(serviceType: ServiceType): SettingsSection = AppReg(serviceType)

  def apply(name: String): SettingsSection = fromString(name)

  val apis: SettingsSection = Internal("APIs")
  val callbacks: SettingsSection = Internal("Callbacks")
  val domains: SettingsSection = Internal("Domains")
  val storage: SettingsSection = Internal("Storage")

  protected val unknown: SettingsSection = Internal("Unknown")

  protected def fromString(str: String): SettingsSection = str match {
    case s"AppReg:$serviceTypeName" if serviceTypeName.nonEmpty => AppReg(ServiceType(serviceTypeName))
    case "APIs" => apis
    case "Callbacks" => callbacks
    case "Domains" => domains
    case _ =>
      log.warn(s"undefined settings section: $str")
      unknown
  }

  implicit def encoder: Encoder[SettingsSection] = Encoder[String].contramap(_.name)

  implicit def decoder: Decoder[SettingsSection] = Decoder[String].emapTry(str => Try(fromString(str)))
}

trait AppRegistrationComponent {
  val $appReg: Signal[Option[PortalAppRegistration]]
  val serviceType: ServiceType
  val isDaemon: Boolean
  val node: Div
  val $initialExpand: Signal[Boolean]

  val editModel: Var[Option[AppRegistrationModel]] = Var(None)
  val formState: Var[Option[AuFormState]] = Var(None)
  val isExpanded: Var[Boolean] = Var(false)

  val iconImages: Var[String] = Var(MaterialIcons.copy)

  val errorView: Signal[Option[ReactiveHtmlElement[html.Div]]] = editModel.signal
    .nestedMap(model =>
      FormsLocalExceptionHandling.errorView(model.formError.signal, false)
        .amend(cls := "slds-m-bottom--small"))

  def iconCopy($textToCopy: Signal[String], clipboardService: ClipboardService): ReactiveHtmlElement[html.Div] = {
    div(child <-- iconImages.signal.map { icon =>
      Icon(
        _ => icon,
        _ => cls := "clickable light",
        _ => cls := "slds-m-right--medium",
      )
    }).amend(
      composeEvents(onClick)(_.sample($textToCopy)) --> Observer[String](onNext = text => {
        iconImages.set(MaterialIcons.done)
        setTimeout(1500) {
          iconImages.set(MaterialIcons.copy)
        }
        clipboardService.copy(text)
      })
    )
  }

  def $redirectUri(model: AppRegistrationModel, portalState: PortalState, apiOrigin: String): Signal[String] = model.intermediateCallbackUrl.signal.flatMap {
    case "" => portalState.$app.map(_.defaultRedirect(apiOrigin))
    case url => Signal.fromValue(url)
  }
}
