package common.ui.notifications

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.airstream_ops.{EventStreamOps, OptionSignalOps, SignalNestedOps, SignalOps, SignalOptionOps, ValueToObservableOps}
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.notifications.TodoType.TodoType
import common.{PortalAppCapability, PortalAppRegistration, PortalTeam, PortalUser, ServiceType, TeamMemberRole}
import org.scalajs.dom
import portal_router.{AccountsPage, AppSettingsPage, PortalRouter, TeamSettingsPage}
import service.ui.messages.Messages
import root_pages.aurinko_pages.app.settings.SettingsSection
import service.apis.portal_api.PortalApi
import service.aurinko_api.AurinkoApi
import service.local_storage.LocalStorageService
import service.portal_state.{PortalState, TeamMemberAccess}
import wvlet.log.Logger

import java.time.Instant
import java.time.temporal.ChronoUnit
import scala.scalajs.js.timers.setTimeout


trait TodosComponent {
  private val log = Logger.of[TodosComponent]

  def defaultTodos: List[TodoType]

  def localStorageService: LocalStorageService

  def $storageKey: Signal[String]

  def expandedTodo: Var[Option[TodoType]]

  val updateBus = new EventBus[Unit]

  def standardTodoView(
                        todo: TodoType,
                        onHide: Unit => Unit,
                        headerElement: ReactiveHtmlElement[dom.html.Element],
                        bodyElement: ReactiveHtmlElement[dom.html.Element],
                        showCloseButton: Signal[Boolean]
                      ): Div =
    ExpansionPanelComponent(
      header = headerElement,
      body = bodyElement.some,
      expandable = true.signaled,
      expanded = expandedTodo.signal.map(_.contains(todo)),
      reverse = true.signaled,
      canHide = showCloseButton,
      onHide = onHide,
      horizontalPadding = false,
      onExpansionChange = Observer[Boolean](exp => if (exp) expandedTodo.set(todo.some))
    ).node

  def hideTodo(t: TodoType, storageKey: String): Unit = {
    localStorageService.addListItem(storageKey, t.toString)

    setTimeout(50) {
      updateBus.emit(())
    }

  }

  def todosInitialCheck: Map[TodoType, Signal[Boolean]]

  def todosCompletenessCheck: Map[TodoType, () => Signal[Boolean]]

  def $initialTodosToShow: EventStream[List[TodoType]] = updateBus.events.debounce(50)
    .flatMap(_ => $storageKey.map(localStorageService.getStringsList).getOrElse(Nil).stream)
    .map(tl => defaultTodos.filter(t => !tl.contains(t.toString)))
    .flatMap(tl => Signal.combineSeq(tl.map(t => todosInitialCheck.getOrElse(t, true.signaled).map {
      case true =>
        log.info(s"show todo(initial):$t")
        t :: Nil
      case false => Nil
    })).map(_.flatten.toList).stream)

  def $checkedTodos: EventStream[List[TodoType]] = $initialTodosToShow
    .flatMap(tl => Signal.combineSeq(tl.map(t => todosCompletenessCheck.getOrElse(t, () => true.signaled)()
      .combineWith($storageKey).map {
      case (true, _) => t :: Nil
      case (false, key) => hideTodo(t, key)
        Nil
//      case _ => Nil
    })).map(_.flatten.toList).stream)


  def $todoElements: Signal[List[Div]]

  def renderElements: Div = div(
    $storageKey.changes.mapTo(()) --> updateBus.writer,

    children <-- $todoElements
  )
}

class AppTodosComponent(portalApi: PortalApi,
                        aurinkoApi: AurinkoApi,
                        override val localStorageService: LocalStorageService,
                        portalRouter: PortalRouter,
                        portalState: PortalState,
                        apiOrigin: String
                       ) extends TodosComponent {
  updateBus.emit(()) // todo: rethink

  private val log = Logger.of[AppTodosComponent]
  private val teamAccess = portalState.$maybeTeam.nestedMap(t =>  new TeamMemberAccess(t.signaled))

  override val defaultTodos: List[TodoType] = TodoType.appTodos
  override val $storageKey: Signal[String] = portalState.$app.map(app => s"hiddenTodos_${app.id}")

  private lazy val appTodosVisibilityCheck: Signal[(Boolean, Boolean)] = portalState.$app.map(_.createdAt)
    .flatMap {
      case d if Instant.now.isAfter(d.plus(5, ChronoUnit.DAYS)) => (false -> false).signaled
      case _ =>

        lazy val $appRegistrations: Signal[List[PortalAppRegistration]] = portalState
          .$appRegs
          .$getOrElse(
            portalState
              .$teamApp
              .map(_.appKey)
              .flatMap(portalApi.requestAppRegs)
              .tap(portalState.updateAppRegs)
              .startWith(Nil)
          )

        val $appConfigured = portalState.$app
          .map(_.websiteUrl.nonEmpty)
          .combineWith(portalState.$app.map(_.logo.nonEmpty))
          .combineWith(portalState.$app.map(_.allowedReturnUrls.nonEmpty))
          .map(t => t._1 && t._2 && t._3)

        val $appPartiallyConfigured = portalState.$app.map(_.websiteUrl.nonEmpty)
          .combineWith(portalState.$app.map(_.logo.nonEmpty))
          .combineWith(portalState.$app.map(_.allowedReturnUrls.getOrElse(Nil).nonEmpty))
          .map(t => t._1 || t._2 || t._3)

        def $showTodos: Signal[Boolean] = $appConfigured.flatMap {
          case false => true.signaled
          case _ => $appRegistrations
            .combineWith(portalState.$app.map(_.capabilities.getOrElse(Set())))
            .map {
              case (appRegs, capabilities) => !appRegs.exists(reg => capabilities match {
                case l if l.contains(PortalAppCapability.apiMailbox) => reg.serviceType == ServiceType.google || reg.serviceType == ServiceType.office365
                case l if l.contains(PortalAppCapability.apiCrm) => reg.serviceType.capabilities.contains(PortalAppCapability.apiCrm)
                case l if l.contains(PortalAppCapability.apiChat) => reg.serviceType == ServiceType.msTeamsBot || reg.serviceType == ServiceType.slackBot
                case _ => false
              })
            }
        }

        def $showCloseButtons: Signal[Boolean] = $appConfigured
          .combineWith($appPartiallyConfigured)
          .flatMap {
            case (full, partial) if full || partial => true.signaled
            case _ =>

              $appRegistrations
                .combineWith(portalState.$app.map(_.capabilities.getOrElse(Set())))
                .map {

                  case (appRegs, capabilities) => appRegs.exists(reg => capabilities match {
                    case l if l.contains(PortalAppCapability.apiMailbox) => reg.serviceType == ServiceType.google || reg.serviceType == ServiceType.office365
                    case l if l.contains(PortalAppCapability.apiCrm) => reg.serviceType.capabilities.contains(PortalAppCapability.apiCrm)
                    case l if l.contains(PortalAppCapability.apiChat) => reg.serviceType == ServiceType.msTeamsBot || reg.serviceType == ServiceType.slackBot
                    case _ => true
                  })
                }
          }

        $showTodos.combineWith($showCloseButtons)
    }


  val todosCompletenessCheck: Map[TodoType, () => Signal[Boolean]] = List(

    TodoType.scheduler_todo ->
      (() => portalState.$app.map(app => Instant.now.isBefore(app.createdAt.plus(30, ChronoUnit.DAYS)))),

    TodoType.app_regs ->
      (() => appTodosVisibilityCheck.map(_._1)),

    TodoType.test_account ->
      (() => appTodosVisibilityCheck.map(_._1))

  )
    .toMap

  val todosInitialCheck: Map[TodoType, Signal[Boolean]] = List(

    TodoType.scheduler_todo ->
      teamAccess.flatMap(_.$traverse(_.minRoleCheck(TeamMemberRole.developer).allowed)).flatMap {
        case Some(true) => portalState.$teamApp
          .map(teamApp =>

          teamApp.app.capabilitiesSet.contains(PortalAppCapability.scheduler) && teamApp.team.trial
        )
        case _ => false.signaled
      },

    TodoType.app_regs ->
      teamAccess.flatMap(_.$traverse(_.minRoleCheck(TeamMemberRole.developer).allowed)).map(_.getOrElse(false)),

    TodoType.test_account ->
      teamAccess.flatMap(_.$traverse(_.minRoleCheck(TeamMemberRole.developer).allowed)).map(_.getOrElse(false))

  )
    .toMap

  val expandedTodo: Var[Option[TodoType]] = Var(None)


  override lazy val $todoElements: Signal[List[Div]] = {
    setTimeout(0) {
      updateBus.emit(())//todo: rethink
    }
      $checkedTodos
      .withCurrentValueOf($storageKey)
      .map { case (tl, storeKey) => tl.map { t =>

        val hideFunc = (_: Unit) => hideTodo(t, storeKey)

        t match {

          case t if t == TodoType.scheduler_todo => standardTodoView(
            todo = t,
            hideFunc,
            headerElement = div("Check out your appointment booking page!"),
            bodyElement = new SchedulerTodoComponent(
              portalApi,
              portalState.$app.map(_.clientId.getOrElse(throw new Exception("Missing app client id"))),
              portalState.$teamApp.map(_.appKey),
              aurinkoApi,
              apiOrigin,
              portalState
            ).node,
            showCloseButton = true.signaled
          )

          case t if t == TodoType.app_regs => standardTodoView(
            todo = t,
            hideFunc,
            headerElement = div("Configure authentication flows."),
            bodyElement = div(
              p(
                cls := "slds-p-bottom--medium",
                "For Aurinko to properly request access to users' mailboxes on behalf of your app it needs to know your app's OAuth keys. " +
                  "If you temporarily skip these steps your authenticating users will see that 'Aurinko.io' is requesting access to their ",
                child.text <-- portalState.$app.map(_.capabilities).map {
                  case list if list.getOrElse(Set()).contains(PortalAppCapability.apiMailbox) => "mailbox (G Suite, Office 365, MS Exchange,...)"
                  case list if list.getOrElse(Set()).contains(PortalAppCapability.apiCrm) => "CRM (Salesforce, Sugar, Hubspot)"
                  case _ => "mailbox"
                },
                "."
              ),
              ul(
                li(
                  cls := "slds-p-bottom--medium",
                  cls <-- portalState.$app.map(appConfig => appConfig.capabilities.getOrElse(Set[PortalAppCapability]()).contains(PortalAppCapability.apiMailbox)).map(if (_) "" else "hidden"),
                  "For Google APIs, provide your Google OAuth app registration ",
                  a("here",
                    href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                      portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection(ServiceType.google).some)))),
                  ". For more information ",
                  a("see the docs ", href := "https://docs.aurinko.io/article/15-google-oauth-setup", target := "_blank"),
                  "."
                ),
                li(
                  cls := "slds-p-bottom--medium",
                  cls <-- portalState.$app.map(appConfig => appConfig.capabilities.getOrElse(Set[PortalAppCapability]()).contains(PortalAppCapability.apiMailbox)).map(if (_) "" else "hidden"),

                  "For Office 365 APIs, provide your Microsoft Azure AD app registration ",
                  a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                    portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection(ServiceType.office365).some)))),
                  ". For more information ",
                  a("see the docs ", href := "https://docs.aurinko.io/article/16-office-365-oauth-setup", target := "_blank"),
                  "."
                ),

                li(
                  cls := "slds-p-bottom--medium",
                  cls <-- portalState.$app.map(appConfig => appConfig.capabilities.getOrElse(Set[PortalAppCapability]()).contains(PortalAppCapability.apiCrm)).map(if (_) "" else "hidden"),
                  "For Salesforce CRM, provide your Salesforce OAuth app registration ",
                  a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                    portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection(ServiceType.salesforce).some)))),
                  "."
                ),
                li(
                  cls := "slds-p-bottom--medium",
                  cls <-- portalState.$app.map(appConfig => appConfig.capabilities.getOrElse(Set[PortalAppCapability]()).contains(PortalAppCapability.apiCrm)).map(if (_) "" else "hidden"),
                  "For Sugar CRM, provide your Sugar OAuth app registration ",
                  a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                    portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection(ServiceType.sugarCRM).some)))),
                  "."
                ),

                li(
                  cls := "slds-p-bottom--medium",
                  cls <-- portalState.$app.map(appConfig => appConfig.capabilities.getOrElse(Set[PortalAppCapability]()).contains(PortalAppCapability.apiCrm)).map(if (_) "" else "hidden"),
                  "For Hubspot CRM, provide your Hubspot OAuth app registration ",
                  a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                    portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection(ServiceType.hubspot).some)))),
                  "."
                ),

                li(
                  cls := "slds-p-bottom--medium",
                  cls <-- portalState.$app.map(appConfig => appConfig.capabilities.getOrElse(Set[PortalAppCapability]()).contains(PortalAppCapability.apiCrm)).map(if (_) "" else "hidden"),
                  "For HighLevel CRM, provide your HighLevel OAuth app registration ",
                  a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                    portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection(ServiceType.highLevel).some)))),
                  "."
                ),

                li(
                  cls := "slds-p-bottom--medium",
                  cls <-- portalState.$app.map(appConfig => appConfig.capabilities.getOrElse(Set[PortalAppCapability]()).contains(PortalAppCapability.apiCrm)).map(if (_) "" else "hidden"),
                  "For NetSuite CRM, provide your NetSuite OAuth app registration ",
                  a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                    portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection(ServiceType.netSuite).some)))),
                  "."
                ),
                li(
                  cls := "slds-p-bottom--medium",
                  cls <-- portalState.$app.map(appConfig => appConfig.capabilities.getOrElse(Set[PortalAppCapability]()).contains(PortalAppCapability.apiCrm)).map(if (_) "" else "hidden"),
                  "For Zoho CRM, provide your Zoho OAuth app registration ",
                  a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                    portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection(ServiceType.zoho).some)))),
                  "."
                ),

                li(
                  cls := "slds-p-bottom--medium",
                  cls <-- portalState.$app.map(appConfig => appConfig.capabilities.getOrElse(Set[PortalAppCapability]()).contains(PortalAppCapability.apiCrm)).map(if (_) "" else "hidden"),
                  "For Pipedrive CRM, provide your Pipedrive OAuth app registration ",
                  a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                    portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection(ServiceType.pipedrive).some)))),
                  "."
                ),

                li(
                  cls := "slds-p-bottom--medium",
                  cls <-- portalState.$app.map(appConfig => appConfig.capabilities.getOrElse(Set[PortalAppCapability]()).contains(PortalAppCapability.apiChat)).map(if (_) "" else "hidden"),
                  "For Microsoft Teams bot, provide your Microsoft OAuth app registration ",
                  a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                    portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection(ServiceType.msTeamsBot).some)))),
                  "."
                ),
                li(
                  cls := "slds-p-bottom--medium",
                  cls <-- portalState.$app.map(appConfig => appConfig.capabilities.getOrElse(Set[PortalAppCapability]()).contains(PortalAppCapability.apiChat)).map(if (_) "" else "hidden"),
                  "For Webex, provide your Webex app registration ",
                  a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                    portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection(ServiceType.webex).some)))),
                  "."
                ),
                li(
                  cls := "slds-p-bottom--medium",
                  cls <-- portalState.$app.map(appConfig => appConfig.capabilities.getOrElse(Set[PortalAppCapability]()).contains(PortalAppCapability.apiChat)).map(if (_) "" else "hidden"),
                  "For Zoom, provide your Zoom app registration ",
                  a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                    portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection(ServiceType.zoom).some)))),
                  "."
                ),

                li(
                  cls := "slds-p-bottom--medium",
                  cls <-- portalState.$app.map(appConfig => appConfig.capabilities.getOrElse(Set[PortalAppCapability]()).contains(PortalAppCapability.apiChat)).map(if (_) "" else "hidden"),
                  "For Slack, provide your Slack OAuth app registration ",
                  a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                    portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection(ServiceType.slackBot).some)))),
                  "."
                ),

                li(
                  cls := "slds-p-bottom--medium",
                  "Specify allowed return URLs ",
                  a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                    portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection.callbacks.some)))),
                  ". For more information ",
                  a("see the docs ", href := "https://docs.aurinko.io/article/13-authentication-flow", target := "_blank"),
                  "."
                ),

                li(
                  "Specify trusted domains ",
                  a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                    portalRouter.router.absoluteUrlForPage(AppSettingsPage(ak, SettingsSection.domains.some)))),
                  "."
                )
              )
            ),
            showCloseButton = appTodosVisibilityCheck.map(_._2)
          )

          case t if t == TodoType.test_account => standardTodoView(
            todo = t,
            hideFunc,
            headerElement = div("Add a test account."),
            bodyElement = div(p(

              "Add a test account ",
              a("here", href <-- portalState.$teamApp.map(_.appKey).map(ak =>
                portalRouter.router.absoluteUrlForPage(AccountsPage(ak)))),
              " to explore Aurinko API. For more information ",
              a("see the docs.", href := "https://docs.aurinko.io/article/14-authentication-scopes", target := "_blank"))
            ),
            showCloseButton = appTodosVisibilityCheck.map(_._2)
          )

        }
      }
      }.startWith(Nil)
  }

}

class GeneralTodosComponent($team: Signal[PortalTeam],
                            $me: Signal[PortalUser],
                            portalRouter: PortalRouter,
                            portalApi: PortalApi,
                            portalState: PortalState,
                            messagesService: Messages,
                            openProfileDialog: () => Unit,
                            override val localStorageService: LocalStorageService) extends TodosComponent {

  override val defaultTodos: List[TodoType] = TodoType.generalTodos

  override val $storageKey: Signal[String] = $team.map(t => s"hiddenTodos_${t.id}")

  override val todosInitialCheck: Map[TodoType, Signal[Boolean]] = Map.empty

  override val expandedTodo: Var[Option[TodoType]] = Var(None)

  override val todosCompletenessCheck: Map[TodoType, () => Signal[Boolean]] = List(

    TodoType.user_email ->
      (() => $me.map(me =>
        !me.verified ||
          Instant.now.isBefore(me.registeredAt.plus(5, ChronoUnit.DAYS)))),

    TodoType.invite_user ->
      (() => $team.map {
        case team if team.role.isAdmin => Instant.now.isBefore(team.createdAt.plus(5, ChronoUnit.DAYS))
        case _ => false
      }),

  ).toMap

  override lazy val $todoElements: Signal[List[Div]] = {
    setTimeout(0) {
      updateBus.emit(())
    }

      $checkedTodos
      .withCurrentValueOf($storageKey)
      .map { case (tl, key) => tl.map(t => {
        val hideFunc = (_: Unit) => hideTodo(t, key)

        t match {

          case TodoType.user_guide => standardTodoView(
            todo = t,
            hideFunc,
            headerElement = div("Find the right integration for your business."),
            bodyElement = p(
              "Browse our docs for use cases, sample code, and developer tools ",
              a("here", href := "https://docs.aurinko.io"),
              "."
            ),
            showCloseButton = key.nonEmpty.signaled
          )

          case t if t == TodoType.user_email => ExpansionPanelComponent(
            header = div(
              child <-- $me.map {
                case u if u.verified => div(
                  cls := "slds-grid slds-grid--vertical-align-center",
                  material.Icon(_ => "check_circle", _ => cls := "green slds-m-right--large small"),
                  span(s"The email ${u.email} is verified", cls := "primary")
                )
                case _ => span("Verify your email")
              },
            ),
            body = Some(div(child.maybe <-- $me.map {
              case u if !u.verified => Some(div(
                span(s"We sent a verification link to ${u.email}. You can also "),
                a("update your email address"),
                onClick --> Observer[dom.MouseEvent](onNext = _ => openProfileDialog()),
                span(" to verify with another email.")))
              case _ => None
            })),
            expandable = $me.map(!_.verified),
            expanded = expandedTodo.signal.map(todo => todo.contains(t)),
            canHide = $me.map(_.verified),
            reverse = true.signaled,
            onHide = hideFunc,
            horizontalPadding = false
          ).node

          case t if t == TodoType.invite_user => standardTodoView(
            todo = t,
            hideFunc,
            headerElement = div("Invite users."),
            bodyElement = div(p(
              "Give your team members access to Aurinko portal ",
              child <-- $team.map(t => a(
                "here",
                href :=  portalRouter.router.absoluteUrlForPage(TeamSettingsPage(t.id)))
              ),
              "."
            )),
            showCloseButton = key.nonEmpty.signaled
          )

        }
      }
      )
      }.startWith(Nil)
  }

}


object TodoType extends Enumeration {
  type TodoType = Value

  val user_guide, user_email, scheduler_todo, app_capabilities, app_regs, test_account, invite_user = Value

  val appTodos: List[TodoType] = scheduler_todo :: app_regs :: test_account :: Nil
  val generalTodos: List[TodoType] = user_guide :: user_email :: invite_user :: Nil
}

case class TodoConfig(todoType: TodoType,
                      visibilityCheck: () => Signal[Boolean] = () => true.signaled,
                      closeButtonVisibilityCheck: () => Signal[Boolean] = () => true.signaled
                     )

