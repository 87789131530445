package common

import cats.implicits.catsSyntaxOptionId
import io.circe.syntax.EncoderOps
import com.raquo.airstream.state.Var
import common.AuthScopePermission.AuthScopePermission
import common.AuthorizationScope.AuthorizationScope
import common.BillingModels.{BillingInfo, PaymentInfo}
import io.circe._
import common.PortalAppType.{PortalAppType, apiOnly}
import common.ServiceProvider.ServiceProvider
import common.TeamMemberRole.TeamMemberRole
import common.PortalAppCapability
import common.ServiceType.{ServiceTypeImpl, log}
import common.UserAuthType.UserAuthType
import common.ui.{AuFormState, AuFormStateExp}
import root_pages.aurinko_pages.app.settings.KeyInputComponent

import java.time._
import scala.language.implicitConversions
import scala.util.Try
import wvlet.log.Logger

import java.time.temporal.ChronoUnit

case class AppKey(teamId: Int, appId: Int)

//object modelHelper {
//  //def stringToOption(givenString: String): Option[String] = if (givenString.isEmpty) None else Some(givenString)
//  def stringToOption(givenString: String): Option[String] = Option(givenString).filter(_.nonEmpty)
//
//  def listToOption[A](givenList: List[A]): Option[List[A]] = if (givenList.isEmpty) None else Some(givenList)
//}

object modelHelper {
  def stringToOption(givenString: String): Option[String] = {
    if (givenString.isEmpty) None else Some(givenString)
  }
}

trait FormModel {
  //  def checkType(model: FormModel)
  val formState = new AuFormStateExp()
  val formError: Var[Option[String]] = Var(None)

}


case class PortalUser(
                       email: String,
                       name: String,
                       registeredAt: Instant,
                       lastLoginAt: Option[Instant],
                       verified: Boolean,
                       teams: List[PortalTeam]
                     ) {
  def toEditModel: PortalUserEditModel = PortalUserEditModel(
    name = Var(name),
    email = Var(email),
    source = this
  )
}

case class PortalUserEditModel(name: Var[String] = Var(""),
                               email: Var[String] = Var(""),
                               source: PortalUser,
                              ) extends FormModel {
  def toApiModel: PortalUser = PortalUser(
    email = source.email,
    name = source.name,
    registeredAt = source.registeredAt,
    lastLoginAt = source.lastLoginAt,
    verified = source.verified,
    teams = source.teams
  )
  def update(model: PortalUserEditModel): Unit = {
    name.set(model.name.now())
    email.set(model.email.now())
  }
}


case class PortalTeam(
                       id: Int, // long?
                       name: String,
                       role: TeamMemberRole,
                       primary: Boolean,
                       apps: Option[List[PortalApplication]],
                       createdAt: Instant,
                       billingInfo: Option[BillingInfo]
                     ) {
  val trial = billingInfo.exists(_.trialDaysLeft > 0)
}


case class PortalApplication(
                              id: Int,
                              name: String,
                              clientId: Option[String],
                              clientSecret: Option[String],
                              domainAlias: Option[String],
                              allowedReturnUrls: Option[List[String]],
                              externalName: Option[String],
                              syncAvailable: Boolean,
                              internalServicesEnabled: Boolean,
                              websiteUrl: Option[String],
                              description: Option[String],
                              logo: Option[ApplicationLogo],
                              createdAt: Instant,
                              allowedOrigins: Option[List[String]],
                              primaryColor: Option[String],
                              secondaryColor: Option[String],
                              applicationType: PortalAppType,
                              capabilities: Option[Set[PortalAppCapability]],
                              availableServiceTypes: Option[Set[ServiceType]],
                              readyForProd: Option[Boolean]
                            ) {


  def defaultRedirect(apiOrigin: String): String =
    domainAlias.map(domainAlias => s"https://$domainAlias/v1/auth/callback").getOrElse(s"$apiOrigin/v1/auth/callback")

  val capabilitiesSet: Set[PortalAppCapability] = capabilities.getOrElse(Set.empty)

  val allowedServiceTypes: Set[ServiceType] = availableServiceTypes
    .getOrElse[Set[ServiceType]](capabilities.map {
      capabilities =>
        ServiceType.ALL
          .filter {
            case st if st == ServiceType.ews365 => internalServicesEnabled
            case st => st.capabilities.intersect(capabilities).nonEmpty
          }
          .toSet
    }.getOrElse(Set.empty))


  def toEditModel: ApplicationModel = {
    ApplicationModel(
      id,
      Var(name),
      clientId,
      clientSecret,
      domainAlias,
      allowedReturnUrls,
      Var(externalName.getOrElse("")),
      syncAvailable,
      internalServicesEnabled,
      Var(websiteUrl.getOrElse("")),
      Var(description.getOrElse("")),
      Var(logo),
      createdAt,
      allowedOrigins,
      Var(primaryColor.getOrElse("")),
      Var(secondaryColor.getOrElse("")),
      Var(applicationType),
      Var(capabilities.getOrElse(Set[PortalAppCapability]())),
      this
    )
  }
}

case class PortalTeamMember(id: Int, // for backwards compatibility with old GET /team/:id/users method
                            email: String,
                            name: String,
                            registeredAt: Instant,
                            lastLoginAt: Option[Instant],
                            verified: Boolean,
                            role: TeamMemberRole,
                            accessibleApps: Set[Int]
                           )

case class TeamMemberOutDto(role: TeamMemberRole, accessibleApps: Set[Int])

case class TeamMemberModel(name: Var[String] = Var(""),
                           email: Var[String] = Var(""),
                           source: PortalTeamMember,
                           role: Var[TeamMemberRole],
                           accessibleApps: Var[Set[Int]] = Var(Set())
                          ) extends FormModel {
  def toApiModel: PortalTeamMember = PortalTeamMember(
    id = source.id,
    email = source.email,
    name = source.name,
    registeredAt = source.registeredAt,
    lastLoginAt = source.lastLoginAt,
    verified = source.verified,
    role = role.now,
    accessibleApps = accessibleApps.now
  )

  def toOutDto: TeamMemberOutDto = TeamMemberOutDto( role = role.now, accessibleApps = accessibleApps.now )
}

case class ApplicationModel(
                             id: Int,
                             name: Var[String] = Var(""),
                             clientId: Option[String] = None,
                             clientSecret: Option[String] = None,
                             domainAlias: Option[String] = None,
                             allowedReturnUrls: Option[List[String]] = None,
                             externalName: Var[String] = Var(""),
                             syncAvailable: Boolean,
                             internalServicesEnabled: Boolean,
                             websiteUrl: Var[String] = Var(""),
                             description: Var[String] = Var(""),
                             logo: Var[Option[ApplicationLogo]] = Var(None),
                             createdAt: Instant,
                             allowedOrigins: Option[List[String]] = None,
                             primaryColor: Var[String] = Var(""),
                             secondaryColor: Var[String] = Var(""),
                             applicationType: Var[PortalAppType] = Var(apiOnly),
                             capabilities: Var[Set[PortalAppCapability]] = Var(Set[PortalAppCapability]()),
                             source: PortalApplication
                           ) extends FormModel {


  def toImmutableModel: PortalApplication = source.copy(
    id = id,
    name = name.now(),
    clientId = clientId,
    clientSecret = clientSecret,
    domainAlias = domainAlias,
    allowedReturnUrls = allowedReturnUrls,
    externalName = modelHelper.stringToOption(externalName.now()),
    syncAvailable = syncAvailable,
    internalServicesEnabled = internalServicesEnabled,
    websiteUrl = modelHelper.stringToOption(websiteUrl.now()),
    description = modelHelper.stringToOption(description.now()),
    logo = logo.now(),
    createdAt = createdAt,
    allowedOrigins = allowedOrigins,
    primaryColor = modelHelper.stringToOption(primaryColor.now()),
    secondaryColor = modelHelper.stringToOption(secondaryColor.now()),
    applicationType = applicationType.now(),
    capabilities = Some(capabilities.now())
  )

  def updateFromModel(model: ApplicationModel) = {
    name.set(model.name.now)
    //clientId.set(givenModel.clientId.getOrElse(""))
    //clientSecret.set(givenModel.clientSecret.getOrElse())
    //domainAlias.set(givenModel.domainAlias.getOrElse(""))
    //allowedReturnUrls.set(givenModel.allowedReturnUrls.getOrElse(Nil))
    externalName.set(model.externalName.now)
    //syncAvailable.set(givenModel.syncAvailable)
    //internalServicesEnabled.set(givenModel.internalServicesEnabled)
    websiteUrl.set(model.websiteUrl.now)
    description.set(model.description.now)
    logo.set(model.logo.now)
    //createdAt.set(givenModel.createdAt)
    //allowedOrigins.set(givenModel.allowedOrigins.getOrElse(Nil))
    primaryColor.set(model.primaryColor.now)
    secondaryColor.set(model.secondaryColor.now)
    //applicationType.set(givenModel.applicationType)
    capabilities.set(model.capabilities.now)
  }

  def updateFromImmutableModel(model: PortalApplication): Unit = {
    name.set(model.name)
    //clientId.set(givenModel.clientId.getOrElse(""))
    //clientSecret.set(givenModel.clientSecret.getOrElse())
    //domainAlias.set(givenModel.domainAlias.getOrElse(""))
    //allowedReturnUrls.set(givenModel.allowedReturnUrls.getOrElse(Nil))
    externalName.set(model.externalName.getOrElse(""))
    //syncAvailable.set(givenModel.syncAvailable)
    //internalServicesEnabled.set(givenModel.internalServicesEnabled)
    websiteUrl.set(model.websiteUrl.getOrElse(""))
    description.set(model.description.getOrElse(""))
    logo.set(model.logo)
    //createdAt.set(givenModel.createdAt)
    //allowedOrigins.set(givenModel.allowedOrigins.getOrElse(Nil))
    primaryColor.set(model.primaryColor.getOrElse(""))
    secondaryColor.set(model.secondaryColor.getOrElse(""))
    //applicationType.set(givenModel.applicationType)
    capabilities.set(model.capabilities.getOrElse(Set.empty[PortalAppCapability]))
  }

  def reseted: ApplicationModel = source.toEditModel
}


case class ApplicationLogo(
                            content: String,
                            mimeType: String
                          ) {
  def toImage: String = s"data:$mimeType;base64,$content"
}

case class PortalAppRegistration(
                                  serviceType: ServiceType,
                                  //                                gid: Option[String],
                                  clientId: String,
                                  clientSecret: Option[String],
                                  clientSecret2: Option[String],
                                  clientSecret3: Option[String],
                                  topicName: Option[String],
                                  intermediateCallbackUrl: Option[String],
                                  defaultScopes: Option[String],
                                  daemon: Boolean,
                                  hasSecret: Boolean,
                                  hasPk: Boolean,
                                  authEndpointUrl: Option[String] = None,
                                ) {

  def toEditModel: AppRegistrationModel = {

    AppRegistrationModel(
      serviceType,
      //    gid,
      Var(clientId),
      Var(clientSecret.getOrElse("")),
      Var(clientSecret2.getOrElse("")),
      Var(clientSecret3.getOrElse("")),
      Var(
        if (serviceType == ServiceType.msTeamsBot) {
          if (topicName.getOrElse("").startsWith("internal:")) {
            topicName.getOrElse("").split("internal:").last
          } else if (topicName.getOrElse("").startsWith("external:")) {
            topicName.getOrElse("").split("external:").last
          } else {
            topicName.getOrElse("")
          }
        } else {
          topicName.getOrElse("")
        }
      ),
      Var(intermediateCallbackUrl.getOrElse("")),
      Var(defaultScopes.getOrElse("")),
      daemon,
      hasSecret,
      hasPk,
      Var(topicName.getOrElse("").startsWith("external")),
      Var(authEndpointUrl.getOrElse[String]("")),
      this.some
    )
  }
}

case class AppRegistrationModel(
                                 serviceType: ServiceType,
                                 //gidVar: Option[String] = None,
                                 clientId: Var[String] = Var(""),
                                 clientSecret: Var[String] = Var(""),
                                 clientSecret2: Var[String] = Var(""),
                                 clientSecret3: Var[String] = Var(""),
                                 topicName: Var[String] = Var(""),
                                 intermediateCallbackUrl: Var[String] = Var(""),
                                 defaultScopes: Var[String] = Var(""),
                                 daemon: Boolean = false,
                                 hasSecret: Boolean = false,
                                 hasPk: Boolean = false,
                                 externalBotId: Var[Boolean] = Var(false), //only for ms teams bot
                                 authEndpointUrl: Var[String] = Var(""),
                                 source: Option[PortalAppRegistration] = None,
                               ) extends FormModel {

  def toImmutableModel: PortalAppRegistration = PortalAppRegistration(
    serviceType,
    //    gid,
    clientId.now(),
    modelHelper.stringToOption(clientSecret.now()),
    modelHelper.stringToOption(KeyInputComponent.stringReplacer(clientSecret2.now())),
    modelHelper.stringToOption(KeyInputComponent.stringReplacer(clientSecret3.now())),
    if (serviceType == ServiceType.msTeamsBot) {
      Some(if (externalBotId.now) s"external:${Some(topicName.now())}"
      else {
        s"internal:${topicName.now()}"
      })
    } else {
      Some(topicName.now())
    },
  //  modelHelper.stringToOption(
//      if (serviceType == ServiceType.msTeamsBot) {
//        if (externalBotId.now) s"external:${topicName.now()}"
//        else s"internal:${topicName.now()}"
//      } else topicName.now()
//    ),
    Some(intermediateCallbackUrl.now()),
    Some(defaultScopes.now()),
    daemon,
    hasSecret,
    hasPk,
    Some(authEndpointUrl.now()).filter(_.nonEmpty),
  )

  def updateModelFromImmutable(appReg: PortalAppRegistration): Unit = {
    clientId.set(appReg.clientId)
    clientSecret.set(appReg.clientSecret.getOrElse(""))
    clientSecret2.set(appReg.clientSecret2.getOrElse(""))
    clientSecret3.set(appReg.clientSecret2.getOrElse(""))
    topicName.set(if (serviceType == ServiceType.msTeamsBot) {
      if (appReg.topicName.getOrElse("").startsWith("internal:")) {
        appReg.topicName.getOrElse("").split("internal:").last
      } else if (appReg.topicName.getOrElse("").startsWith("external:")) {
        appReg.topicName.getOrElse("").split("external:").last
      } else {
        appReg.topicName.getOrElse("")
      }
    } else {
      appReg.topicName.getOrElse("")
    })
    intermediateCallbackUrl.set(appReg.intermediateCallbackUrl.getOrElse(""))
    externalBotId.set(appReg.topicName.getOrElse("").startsWith("external"))
    authEndpointUrl.set(appReg.authEndpointUrl.getOrElse(""))
    //    daemonVar.set(appReg.daemon)
  }

  def resetModel(): Unit = {
    //    serviceType.set(ServiceType.ews365)
    //    gidVar.set("")
    clientId.set("")
    clientSecret.set("")
    clientSecret2.set("")
    clientSecret3.set("")
    topicName.set("")
    intermediateCallbackUrl.set("")
    authEndpointUrl.set("")
    //    daemonVar.set(false)
  }

  def updateFromModel(model: AppRegistrationModel) = {
    clientId.set(model.clientId.now)
    clientSecret.set(model.clientSecret.now)
    clientSecret2.set(model.clientSecret2.now)
    clientSecret3.set(model.clientSecret2.now)
    topicName.set(topicName.now)
    intermediateCallbackUrl.set(model.intermediateCallbackUrl.now)
    externalBotId.set(externalBotId.now)
    authEndpointUrl.set(model.authEndpointUrl.now())
  }
}

//case object AppRegistrationModel {
//  def getEmptyModel: AppRegistrationModel =
//    AppRegistrationModel(Var(ServiceType.ews365), Var(""), Var(""), Var(""), Var(""), Var(""), Var(""), Var(false))
//}

case class PortalInvitationState(
                                  authorized: Boolean,
                                  userExists: Boolean,
                                  alreadyInTeam: Boolean,
                                  username: String,
                                  teamName: String,
                                  teamId: Int,
                                  email: String
                                )

case class PortalInvitation(
                             id: Option[Int],
                             email: String,
                             username: String,
                             role: TeamMemberRole,
                             createdAt: Option[Instant],
                             accessibleApps: Set[Int]
                           ) {

  def toEditModel: InvitationModel = InvitationModel(
    Var(id),
    Var(email),
    Var(username),
    Var(role),
    Var(createdAt),
    Var(accessibleApps),
    this.some
  )
}


case class InvitationModel(
                            id: Var[Option[Int]] = Var(None),
                            email: Var[String] = Var(""),
                            username: Var[String] = Var(""),
                            role: Var[TeamMemberRole] = Var(TeamMemberRole.collaborator),
                            createdAt: Var[Option[Instant]] = Var(None),
                            accessibleApps: Var[Set[Int]] = Var(Set()),
                            source: Option[PortalInvitation] = None
                          ) extends FormModel {
  def toImmutableModel: PortalInvitation = PortalInvitation(
    id.now(),
    email.now(),
    username.now(),
    role.now(),
    createdAt.now(),
    accessibleApps.now()
  )

  def update(model: InvitationModel): Unit = { // for updating from cached form
    id.set(model.id.now)
    email.set(model.email.now)
    username.set(model.username.now)
    role.set(model.role.now)
    accessibleApps.set(model.accessibleApps.now)
  }

  def initial: InvitationModel = source.map(_.toEditModel).getOrElse(InvitationModel())

}

case class PortalUserAppPermissions(id: Int,
                                    email: String,
                                    name: String,
                                    teamMemberRole: TeamMemberRole,
                                    appPermissions: Option[List[PortalUserAppPermission]]
                                   )

sealed abstract class PortalUserAppPermission {

  def value: String

}

case class PortalUserAppPermissionsOut(id: Int, appPermissions: Option[List[PortalUserAppPermission]])

object PortalUserAppPermission {

  case class PortalUserAppPermissionImpl(value: String) extends PortalUserAppPermission


  import io.circe._, io.circe.generic.codec.DerivedAsObjectCodec.deriveCodec

  implicit def encoder: Encoder[PortalUserAppPermission] = Encoder[String].contramap(_.value)
  implicit val decoder: Decoder[PortalUserAppPermission] = Decoder[String].map(PortalUserAppPermissionImpl)
}


case class PortalAccount(
                          id: Int,
                          parentId: Option[Int] = None,
                          serviceType: ServiceType = common.ServiceType.unknown,
                          status: Option[String] = None,
                          daemon: Option[Boolean] = None,
                          loginString: Option[String] = None,
                          loginString2: Option[String] = None,
                          email: Option[String] = None,
                          name: Option[String] = None,
                          serverUrl: Option[String] = None,
                          serverUrl2: Option[String] = None,
                          clientOrgId: Option[String] = None,
                          authUserId: Option[String] = None,
                          authOrgId: Option[String] = None,
                          createdAt: Option[Instant] = None,
                          active: Boolean = false,
                          hasPortalToken: Option[Boolean] = None,
                          hasApiErrors: Option[Boolean] = None,
                          `type`: String = "",
                          tokens: Option[List[AccountToken]] = None,
                          organization: Option[Organization] = None,
                          userAccountType: Option[String] = None,
                          isEndUserAccount: Option[Boolean] = None,
                          userId: Option[String] = None,
                          bookingCount: Option[Int] = None,
                          trackingActive: Option[Boolean] = None,
                          templatesCount: Option[Int] = None,
                        ) {

  def toEditModel: PortalAccountEditModel = PortalAccountEditModel(
    name = Var(name.getOrElse("")),
    clientOrgId = Var(clientOrgId.getOrElse("")),
    source = this
  )
}

case class PortalAccountEditModel(
                                   name: Var[String] = Var(""),
                                   clientOrgId: Var[String] = Var(""),
                                   source: PortalAccount
                                 ) extends FormModel {
  def toApiModel: PortalAccount = PortalAccount(
    id = source.id,
    parentId = source.parentId,
    serviceType = source.serviceType,
    status = source.status,
    daemon = source.daemon,
    loginString = source.loginString,
    loginString2 = source.loginString2,
    email = source.email,
    name = Some(name.now),
    serverUrl = source.serverUrl,
    serverUrl2 = source.serverUrl2,
    clientOrgId = Some(clientOrgId.now),
    authUserId = source.authUserId,
    authOrgId = source.authOrgId,
    createdAt = source.createdAt,
    active = source.active,
    hasPortalToken = source.hasPortalToken,
    hasApiErrors = source.hasApiErrors,
    `type` = source.`type`,
    tokens = source.tokens,
    organization = source.organization,
    userAccountType = source.userAccountType,
    isEndUserAccount = source.isEndUserAccount,
    userId = source.userId,
  )

  def update(model: PortalAccountEditModel): Unit = {
    name.set(model.name.now)
    clientOrgId.set(model.clientOrgId.now)
  }

  def toJson: Json = Map(
    "clientOrgId" -> clientOrgId.now(),
    "name" -> name.now(),
  ).asJson.deepDropNullValues

  def initial: PortalAccountEditModel = source.toEditModel
}

case class AccountToken(
                         id: Int,
                         oauthClientId: Option[String],
                         obtainedViaPortal: Boolean,
                         scopes: Option[List[String]],
                         status: String,
                         lastActivity: Option[Instant],
                         errorDesc: Option[String]
                       )

case class TokenInfo(obtainedAt: Instant, token: String)

case class SingleTypeAccountsStats(
                                    total: Int = 0,
                                    hasErrors: Int = 0,
                                    inactive: Int = 0
                                  )

case class AccountsStats(
                          total: SingleTypeAccountsStats = SingleTypeAccountsStats(),
                          daemon: SingleTypeAccountsStats = SingleTypeAccountsStats(),
                          personal: SingleTypeAccountsStats = SingleTypeAccountsStats(),
                          managed: SingleTypeAccountsStats = SingleTypeAccountsStats()
                        )

case class Usage(
                          id: Long,
                          key: Option[String] = None,
                          accounts: Option[List[AccountsUsage]] = None,
                          calls: Long,
                          bytes: Long,
                          reportStart: LocalDate,
                          reportEnd: LocalDate,
                          pastDays: Option[Int] = None,
                        )

case class AccountsUsage (
                           id: Long,
                           calls: Long,
                           bytes: Long,
                           removed: Boolean
                         )


case class AccountError(
                         apiPath: String,
                         apiQuery: String,
                         errorCode: String,
                         errorMessage: String,
                         occurrencesCount: Int,
                         requestId: String,
                         requestTime: Instant,
                         tokenId: Int
                       )

case class Organization(
                         id: Int,
                         serviceProvider: ServiceProvider,
                         xid: String,
                         domain: Option[String],
                         name: Option[String],
                         createdAt: Instant
                       ) {
  def toEditModel: OrganizationEditModel = OrganizationEditModel(
    domain = Var(domain.getOrElse("")),
    name = Var(name.getOrElse("")),
    source = this
  )
}

case class OrganizationEditModel(
                                  domain: Var[String] = Var(""),
                                  name: Var[String] = Var(""),
                                  source: Organization,
                                ) extends FormModel {
  def toApiModel: Organization = Organization(
    id = source.id,
    serviceProvider = source.serviceProvider,
    xid = source.xid,
    domain = Some(domain.now()),
    name = Some(name.now()),
    createdAt = source.createdAt,
  )

  def update(model: OrganizationEditModel): Unit = {
    domain.set(model.domain.now)
    name.set(model.name.now)
  }

  def toJson: Json = Map(
    "domain" -> domain.now(),
    "name" -> name.now(),
  ).asJson.deepDropNullValues
}

case class AccountFilters(
                           onlyWithErrors: Var[Boolean] = Var(false),
                           hasValidToken: Var[Option[Boolean]] = Var(None),
                           searchText: Var[String] = Var("")
                         ) {
  def toMap: Map[String, Option[String]] = Map(
    "onlyWithErrors" -> Option.when(onlyWithErrors.now) {
      "true"
    },
    "hasValidToken" -> Option.when(hasValidToken.now.isDefined) {
      hasValidToken.now.get.toString
    },
    "search" -> Option.when(searchText.now.trim.nonEmpty) {
      searchText.now
    }
  )

  def toFiltersString: String = (Option.when(onlyWithErrors.now) {
    "hasErrors"
  } ::
    Option.when(hasValidToken.now.isDefined) {
      if (hasValidToken.now.get) "active" else "inactive"
    } :: Nil).collect { case Some(c) => c }.mkString(",")
}

case class AuthAccountResponse(
                                status: String,
                                details: Option[String],
                                token: Option[String] = None,
                                //state: String,
                                accountId: Option[Int] = None,
                                var isDaemon: Option[Boolean] = None,
                              )

case class UserFilters(
                        searchText: Var[String] = Var(""),
                        orgId: Var[Option[Int]] = Var(None)
                      ) {
  def toMap: Map[String, Option[String]] = Map(
    "orgId" -> Option.when(orgId.now.isDefined) {
      orgId.now.get.toString
    },
    "search" -> Option.when(searchText.now.trim.nonEmpty) {
      searchText.now
    }
  )
}

case class OrgFilters(
                       searchText: Var[String] = Var(""),
                       serviceProvider: Var[Option[ServiceProvider]] = Var(None)
                     ) {
  def toMap: Map[String, Option[String]] = Map(
    "provider" -> Option.when(serviceProvider.now.isDefined) {
      serviceProvider.now.get.toString
    },
    "search" -> Option.when(searchText.now.trim.nonEmpty) {
      searchText.now
    }
  )
}

case class EndUser(
                    id: String,
                    appId: Int,
                    email: Option[String],
                    authOrgId: Option[String],
                    trustedIdentity: Boolean,
                    createdAt: Instant,
                    accounts: Option[List[PortalAccount]],
                    externalIdType: Option[UserAuthType],
                    lastActivity: Option[Instant],
                    name: Option[String]
                  )

case class AurinkoApiPage[T](
                              records: List[T],
                              offset: Int,
                              done: Boolean,
                              totalSize: Int
                            )

case class Storage(
                    //                    storeId: Int,
                    createdAt: Option[Instant] = None,
                    updatedAt: Option[Instant] = None,
                    totalSize: Int,
                    data: Map[String, String],
                    offset: Int
                  )


object PortalAppType extends Enumeration with JsonEnum {
  case class Val(name: String, label: String, icon: String, defaultCapabilities: Set[PortalAppCapability]) extends super.Val(name)

  type PortalAppType = Val
  type EnumValue = Val

  val apiOnly: PortalAppType = Val("apiOnly", "application", "api", Set.empty)
  val emailAddon: PortalAppType = Val("emailAddon", "Outlook/Gmail addon", "forward_to_inbox", Set(PortalAppCapability.apiMailbox, PortalAppCapability.endUserSessions))
  val botApp: PortalAppType = Val("botApp", "Teams/Slack app", "chat_bubble_outline", Set(PortalAppCapability.apiChat, PortalAppCapability.endUserSessions))
}

object TeamMemberRole extends Enumeration with JsonEnum {
  case class Val(name: String, label: String, isAdmin: Boolean = false) extends super.Val(name)

  type TeamMemberRole = Val
  type EnumValue = Val

  val owner: TeamMemberRole = Val("owner", "Owner", isAdmin = true)
  val admin: TeamMemberRole = Val("admin", "Administrator", isAdmin = true)
  val developer: TeamMemberRole = Val("developer", "Developer")
  val collaborator: TeamMemberRole = Val("collaborator", "Collaborator")

  val ALL: List[TeamMemberRole] = owner :: admin :: developer :: collaborator :: Nil
}

// TODO: deprecated
object AuthorizationScope extends Enumeration with JsonEnum {
  case class Val(name: String, permissions: List[AuthScopePermission] = Nil) extends super.Val(name)

  type AuthorizationScope = Val
  type EnumValue = Val

  val calendar: AuthorizationScope = Val("Calendar", AuthScopePermission.read :: AuthScopePermission.readWrite :: Nil)
  val mail: AuthorizationScope = Val("Mail", AuthScopePermission.read :: AuthScopePermission.readWrite :: AuthScopePermission.drafts :: AuthScopePermission.send :: Nil)
  val contacts: AuthorizationScope = Val("Contacts", AuthScopePermission.read :: AuthScopePermission.readWrite :: Nil)
  val tasks: AuthorizationScope = Val("Tasks", AuthScopePermission.read :: AuthScopePermission.readWrite :: Nil)
  val chat: AuthorizationScope = Val("Chat")

  val mlbxScopes: List[AuthorizationScope] = calendar :: mail :: contacts :: tasks :: Nil
  val botScopes: List[AuthorizationScope] = chat :: Nil

  val all: List[AuthorizationScope] = calendar :: mail :: contacts :: tasks :: chat :: Nil

}

// TODO: deprecated
object AuthScopePermission extends Enumeration with JsonEnum {
  case class Val(value: String, label: String) extends super.Val(value)

  type AuthScopePermission = Val
  type EnumValue = Val

  val read: AuthScopePermission = Val("Read", "Read")
  val readWrite: AuthScopePermission = Val("ReadWrite", "Read & write")
  val send: AuthScopePermission = Val("Send", "Send")
  val drafts: AuthScopePermission = Val("Drafts", "Drafts")
}

object ServiceProvider extends Enumeration with JsonEnum {
  private val log = Logger.of[ServiceProvider.type]

  type ServiceProvider = Value
  type EnumValue = Value

  val Office365, Google,

  EWS,

  Repfabric, Salesforce, Salesflare, SugarCRM, HighLevel, Hubspot, Pipedrive,

  Teamwork,

  EclipseERP, NetSuite,

  Slack,

  Zoom,

  Webex,

  Zoho

  //      IMAP,
  //      Yahoo,
  //      AOL,
  //      iCloud,
  //      BigPond,
  //      Fastmail
  = Value

  override def fromString(str: String): EnumValue = {
    try {
      withName(str)
    } catch {
      case _: Throwable =>
        log.warn(s"undefined service provider, should be create a new $str")
        Value(str)
    }
  }

  val list: List[ServiceProvider] = Office365 :: Google ::
    EWS ::
    Repfabric :: Salesflare :: Salesforce :: SugarCRM :: HighLevel :: Hubspot :: Pipedrive ::
    Teamwork ::
    EclipseERP :: NetSuite ::
    Slack ::
    Zoom ::
    Webex ::
    Zoho :: Nil
}

object StoreType extends Enumeration with JsonEnum {
  case class Val(name: String, label: String) extends super.Val(name)

  type StoreType = Val
  type EnumValue = Val

  val organization: StoreType = Val("organizations", "Organization")
  val user: StoreType = Val("users", "User")
  val account: StoreType = Val("accounts", "Account")
  val application: StoreType = Val("applications", "Application")
}

object UserAuthType extends Enumeration with JsonEnum {
  case class Val(name: String, label: String) extends super.Val(name)

  type UserAuthType = Val
  type EnumValue = Val

  val exchangeIdentity: UserAuthType = Val("exchangeIdentity", "Microsoft id token ")
  val cookieOrHeader: UserAuthType = Val("cookieOrHeader", "Cookie/Header")

}



//trait JsonEnum {
//  self: Enumeration =>
//
//  protected type EnumValue <: self.Value
//
//  implicit def valueToEnumValue(x: Value): EnumValue = x.asInstanceOf[EnumValue]
//
//  implicit def encoder: Encoder[EnumValue] = Encoder[String].contramap(_.toString)
//
//  implicit def decoder: Decoder[EnumValue] = Decoder[String].emapTry(str => Try(withName(str)))
//}

trait JsonEnum {
  self: Enumeration =>

  protected type EnumValue <: self.Value

  protected def fromString(str: String): EnumValue = withName(str)

  implicit def valueToEnumValue(x: Value): EnumValue = x.asInstanceOf[EnumValue]

  implicit def encoder: Encoder[EnumValue] = Encoder[String].contramap(_.toString)

  implicit def decoder: Decoder[EnumValue] = Decoder[String].emapTry(str => Try(fromString(str))) // тут fromString используем
}

case class EmptyFieldException(name: String = "") extends Exception(s"Field $name is empty")


case class ClientSubscription(
                               id: Int,
                               resource: Option[String] = None,
                               active: Boolean = false,
                               notificationUrl: Option[String] = None,
                               latestDeliveredEventTime: Option[Instant] = None,
                               providerError: Option[String] = None,
                               deliveryFailSince: Option[Instant] = None,
                               deliveryError: Option[String] = None,
                               hasProcessingErrors: Boolean = false
                             )

case class ProcessingErrors(
                             message: String,
                             createdAt: Instant
                           )

case class ClientSubscriptionEvent(
                                    payload: Option[String] = None,
                                    createdAt: Option[Instant] = None
                                  )

//case class EventFilters(
//                           undelivered: Var[Boolean] = Var(false),
//
//
//                         ) {
//  def toMap: Map[String, Option[String]] = Map(
//    "undelivered" -> Option.when(undelivered.now) {
//      "true"
//    },
//  )
//
//  def toFiltersString: String = (Option.when(undelivered.now) {
//    "hasErrors"
//  } :: Nil).collect { case Some(c) => c }.mkString(",")
//}


case class ApiEndpoint(
                        apiEndpointType: ApiEndpointType,
                      ) {

}

case class ApiEndpointType(
                            val name: String,
                          )


case class BookProfile(
                        id: Option[Int] = None,
                        name: Option[String],
                        durationMinutes: Int,
                        availabilityStep: Int,
                        timeAvailableFor: Option[String],
                        subject: Option[String],
                        description: Option[String],
                        location: Option[String] = None,
                        context: Option[String] = None,
                        startConference: Option[Boolean],
                        //timezone: Option[String] = None,
                      )









